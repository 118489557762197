import React from 'react';
import '../Styles/Components/ThirdSection.css';

export class ThirdSection extends React.Component{

    constructor(props){
        super(props);
        this.sendForm = this.sendForm.bind(this);
    }

    displaySuccess(){
        alert("E-mail enviado com sucesso!");
    }

    displayError(){
        alert("Erro ao enviar!");
    }

    loading(button, boolean){
        button.disabled = boolean;
        if(boolean === true){
            button.innerText = 'Enviando...';
        }else{
            button.innerText = 'Enviar';
        }
    }

    getFormObject(form){
        const formObject = {};
        const fields = form.querySelectorAll('[name]');
        fields.forEach((field) => {
            formObject[field.getAttribute('name')] = field.value;
        })
        return formObject;
    }

    async sendForm(event){
        event.preventDefault();
        const nome = document.getElementById('nome');
        const texto = document.getElementById('texto-email');
        texto.setAttribute('value','SERT - Novo Pedido de Orçamento de: ' + nome.value);

        const button = document.getElementById('button');
        const form = document.getElementById('form-email');
        let url = '';
        if(form){
            url = form.getAttribute('action');
        }
        
        try{
            this.loading(button, true);
            await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(this.getFormObject(form)),
            });
            await fetch('https://formsubmit.co/ajax/rodolfomorais@sertmkt.com.br', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify(this.getFormObject(form)),
            });
            this.loading(button, false);
            this.displaySuccess();
        }catch(error){
            this.displayError();
            throw new Error(error);
        }
    }

    render(){

        return(

            <div id='orcamento' className='orcamento'>
                <div className='op-background'>
                    <div className='preencha'>
                        <h1>O seu <span style={{color : '#F17121'}}>sucesso</span> é a nossa <span style={{color : '#F17121'}}>prioridade!</span></h1>
                        <p>Preencha o formulário abaixo e juntos atingiremos seus objetivos:</p>
                    </div>
                    <form action='https://formsubmit.co/ajax/contato@sertmkt.com.br' method='POST' className='form-orcamento' id='form-email' onSubmit={this.sendForm}>
                        <div className='nome-telefone'>
                            <input type='text' name='Nome' id='nome' placeholder='Nome Completo:' required/>
                            <input type='tel' name='Telefone' id='telefone' placeholder='Telefone:' required/>
                        </div>
                        <input type='email' name='E-mail' id='email' placeholder='E-mail:' required/>
                        <textarea name='Descrição' id='descricao' placeholder='Descreva o seu negócio (Opcional):'></textarea>
                        <select name='Faturamento'>
                            <option value='????' selected disabled style={{color:'lightgray'}}>Porte da Empresa (Opcional)</option>
                            <option value='Pequeno'>Pequeno</option>
                            <option value='Médio'>Médio</option>
                            <option value='Grande'>Grande</option>
                        </select>
                        <div className='divbutton'>
                            <button type='submit' id='button'>Enviar</button>
                        </div>
                        <input type='hidden' name='_subject' value='SERT - Novo Pedido de Orçamento!' id='texto-email'/>
                        <input type='text' name='_honey' style={{display:'none'}}/>
                    </form>
                    <div className='desc'>
                        <p>Obs: O pedido de orçamento é totalmente <span style={{color : '#F17121'}}>GRATUITO</span>!</p>
                    </div>
                </div>
            </div>

        )

    }

}