import React from 'react';
import '../Styles/Components/SecondSection.css';

export class SecondSection extends React.Component{

    render(){

        return(

            <div id='foco' className='section'>
                <div className='op-background'>
                    <div className='box-examples'>
                            <img src={require('../Images/logo_examples.png')} alt='Logo Exemplos' data-aos='zoom-in' data-aos-duration='1000' data-aos-offset="200"/>
                    </div>
                    <div className='linha' data-aos='fade-up' data-aos-duration='1000' data-aos-offset="200"></div>
                    <div className='textos'>
                        <h1 data-aos='fade-right' data-aos-duration='1000' data-aos-offset="200">Mais do que tráfego pago, campanhas <span style={{color : '#F17121'}}>estratégicas</span> e <span style={{color : '#F17121'}}>personalizadas</span> para seu negócio.</h1>
                        <p data-aos='fade-left' data-aos-duration='1000' data-aos-offset="200">Sem uma estratégia adequada, o marketing digital pode levar a investimentos ineficientes e de curta duração. Com a crescente competitividade no mercado, o básico não é mais o bastante.<br/><br/>
                            Por isso, em vez de campanhas prontas, criamos estratégias exclusivas para o seu negócio, garantindo que você possa investir com confiança e transparência.</p>
                    </div>
                    <div className='metas'>
                        <div className='metas-linha'>
                            <img src={require('../Images/check.png')} alt='Check Logo' data-aos='fade-right' data-aos-duration='1000' data-aos-offset="200"/>
                            <p>Estou pronto para crescer;</p>
                        </div>
                        <div className='metas-linha'>
                            <img src={require('../Images/check.png')} alt='Check Logo' data-aos='fade-right' data-aos-duration='1000' data-aos-offset="200"/>
                            <p>Anseio por mais vendas;</p>
                        </div>
                        <div className='metas-linha'>
                            <img src={require('../Images/check.png')} alt='Check Logo' data-aos='fade-right' data-aos-duration='1000' data-aos-offset="200"/>
                            <p>Viso novos clientes;</p>
                        </div>
                        <div className='metas-linha'>
                            <img src={require('../Images/check.png')} alt='Check Logo' data-aos='fade-right' data-aos-duration='1000' data-aos-offset="200"/>
                            <p>Almejo escalonar o negócio.</p>
                        </div>
                        <p data-aos='fade-up' data-aos-duration='1000' data-aos-offset="200">Se você se encaixa em algum desses perfis você precisa dos nossos serviços!</p>
                    </div>
                </div>
            </div>

        )

    }

}