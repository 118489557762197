import React from 'react';
import '../Styles/Pages/Main.css';
import { MainNavbar } from '../Components/MainNavbar';
import { Banner } from '../Components/Banner';
import { FirstSection } from '../Components/FirstSection';
import { SecondSection } from '../Components/SecondSection';
import { ThirdSection } from '../Components/ThirdSection';
import { WhatsAppLink } from '../Components/WhatsAppLink';
import { Footer } from '../Components/Footer';

export class Main extends React.Component{

    render(){

        return(

          <div className="main">
            <MainNavbar/>
            <Banner/>
            <FirstSection/>
            <SecondSection/>
            <ThirdSection/>
            <WhatsAppLink/>
            <Footer/>
          </div>

        )

    }

}
