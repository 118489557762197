import React from 'react';
import '../Styles/Components/Footer.css';

export class Footer extends React.Component{

    render(){

        return(

            <div id='footer' className='footer'>
                <p>Copyright © 2023 - Sert Marketing Digital. All rights reserved.</p>
            </div>

        )

    }

}