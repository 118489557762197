import React from 'react';
import TypeIt from "typeit-react";
import '../Styles/Components/FirstSection.css';

export class FirstSection extends React.Component{

    render(){

        return(

            <div id='quem' className='section'>
                <div className='op-background'>
                    <div className='somossert-container'>
                        <div className='somossert' data-aos='fade-in' data-aos-duration='1000' data-aos-offset="0">
                            <h1>Nós somos a <span style={{color : '#F17121'}}>SERT</span>, muito prazer!</h1>
                            <TypeIt id='typeit-text' element={"p"} options={{
                                                        speed: 30,
                                                        waitUntilVisible: true,
                                                    }}>
                                Bem-vindo  à nossa empresa de marketing digital!<br/><br/>
                                Estamos focados em impulsionar o seu sucesso online. Com abordagem inovadora, ajudamos nossos clientes a alcançar resultados excepcionais.<br/><br/>
                                Maximize seu retorno sobre investimento em anúncios online e conquiste seu espaço no mundo digital com nosso conhecimento e técnicas atualizadas.<br/><br/>
                                Seja qual for seu desafio, estamos prontos para enfrentá-lo e <span style={{color : '#F17121'}}>crescer juntos</span>.
                            </TypeIt>
                        </div>
                    </div>
                    <div className='quadros'>
                        <div className='quadro' data-aos='fade-up' data-aos-duration='1000' data-aos-offset="200">
                            <img src={require('../Images/social-media-marketing.png')} alt='Icone Gestão de Tráfego'/>
                            <h1>Gestão de Tráfego</h1>
                            <p>Desenvolvimento e execução de campanhas de tráfego pago em plataformas como Google Ads, Facebook Ads, Instagram Ads e TikTok Ads.</p>
                        </div>
                        <div className='quadro' data-aos='fade-up' data-aos-delay='100' data-aos-duration='1000' data-aos-offset="200">
                            <img src={require('../Images/tactical.png')} alt='Icone Estratégias de Marketing'/>  
                            <h1>Estratégias de Marketing</h1>
                            <p>Personalizamos estratégias de marketing para cada etapa de funil de vendas, assegurando a presença da sua empresa em todo processo de compra do cliente.</p>
                        </div>
                    </div>
                    <div className='quadros'>
                        <div className='quadro' data-aos='fade-up' data-aos-duration='1000' data-aos-offset="200">
                            <img src={require('../Images/chat.png')} alt='Icone Comunicação Efetiva'/>
                            <h1>Comunicação Efetiva</h1>
                            <p>Aprimoramos a comunicação com base no seu público-alvo, tornando suas estratégias de marketing altamente efetivas e impulsionando suas vendas.</p>
                        </div>
                        <div className='quadro' data-aos='fade-up' data-aos-delay='100' data-aos-duration='1000' data-aos-offset="200">
                            <img src={require('../Images/financial-report.png')} alt='Icone Análise de Resultados'/>
                            <h1>Análise de Resultados</h1>
                            <p>Realizamos análises minuciosas, otimizações personalizadas e fornecemos relatórios completos para maximizar os resultados da sua estratégia de marketing.</p>
                        </div>
                    </div>
                </div>
            </div>

        )

    }

}