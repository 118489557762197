import React from 'react';
import '../Styles/Components/MainNavbar.css';
import logo from '../Images/logo.png';
import AnchorLink from 'react-anchor-link-smooth-scroll'

export class MainNavbar extends React.Component{

    render(){

        return(

            <div className='navbar'>
                <div className='divlogo'>
                    <img src={logo} className="logo" alt="logo"/>
                </div>
                

                <div className='links'>
                    <AnchorLink href='#home' offset='75px'>HOME</AnchorLink>
                    <AnchorLink href='#quem'>QUEM SOMOS?</AnchorLink>
                    <AnchorLink href='#foco'>NOSSO FOCO</AnchorLink>
                    <AnchorLink href='#orcamento'>ORÇAMENTO</AnchorLink>
                </div>

            </div>

        )

    }

}