import React from 'react';
import '../Styles/Components/Banner.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export class Banner extends React.Component{

    render(){

        return(

            <div id='home' className='banner'>
                <div className='title' data-aos='fade-up' data-aos-duration='1000' data-aos-once='true'>
                    <h1>GESTÃO DE TRÁFEGO PAGO</h1>
                </div>
                <p data-aos='fade-up' data-aos-duration='1000' data-aos-once='true'>Foque no <span style={{color : '#F17121'}}>crescimento</span> do seu <span style={{color : '#F17121'}}>negócio</span> enquanto cuidamos dos seus anúncios.</p>
                <button className='button' data-aos='fade-in' data-aos-delay='200' data-aos-duration='1000' data-aos-once='true'>
                    <AnchorLink href='#orcamento'>PEÇA UM ORÇAMENTO GRATUITO AGORA</AnchorLink>
                </button>
            </div>

        )

    }

}