import React from 'react';
import '../Styles/Components/WhatsAppLink.css';

export class WhatsAppLink extends React.Component{

    render(){

        return(

            <a className='link-whats' href='https://api.whatsapp.com/send?1=pt_BR&phone=5548996052204'>
                <img src={require('../Images/whatsapp.png')} alt='WhatsApp Logo'/>
            </a>

        )

    }

}